import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Global } from 'asap-crud';
import { Router } from '@angular/router';

@Injectable()
export class AuthService extends BaseService {

    constructor(
        public http: HttpClient,
        public global: Global,
        public router: Router
    ) {
        super(http, 'api', '');
    }

    public login(user) {
        return this.createResource(user, {router: 'oauth/access_token'});
    }

    public register(user, clientID) {
        const body = {
            client_id: clientID,
            user
        };
        return this.createResource(body, {router: 'v2/client/register'});
    }

    public isAuthenticated(): boolean {
        const token: string = sessionStorage.getItem('token');
        if (!token) {
            return false;
        }
        return true;
    }

    public setSessionToken(data): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const token = 'Bearer' + ' ' + data.token;
            sessionStorage.setItem('token', token);
            setTimeout(() => {
                this.getUserLogged().subscribe((response: any) => {
                    this.verifica().subscribe(verifica => {
                        if (response.success === false) {
                            resolve(false);
                        } else {
                            sessionStorage.setItem('loggedUser', JSON.stringify(response));
                            resolve(true);
                        }
                    });
                }, err => reject(err));
            }, 100);
        });
    }

    public socialAuth(body) {
        return this.createResource(body, {router: 'social_auth/grupo'});
    }

    public verifica() {
        return this.getResources({router: 'v2/client/verifica'});
    }

    public complement() {
        return this.getResources({router: 'v2/admin/user/form'});
    }

    public complementSave(id: number, data: any) {
        return this.updateResource(data, {router: 'v2/perfil/sobre/' + id});
    }

    public getUserLogged() {
        return this.getResources({router: 'v2/client/user/authenticated'});
    }

    public userInfo(id) {
      return this.getResources({router: 'v2/client/feed/userInfo'});
    }

    public verifyClient(clientID: number) {
      return this.createResource({client_id: clientID}, {router: 'v2/cliente/verifyClient'});
    }

    public dismissStorage(): void {
      sessionStorage.clear();
    }

    public syncActiveClient(client: string): void {
      localStorage.setItem('client', client);
    }

    public getActiveClient(): any {
      return +localStorage.getItem('client');
    }

    public resetPassword(body): any {
        return this.createResource(body, {router: 'v2/client/reset'});
    }


    public doLogon(user) {
        return new Promise((resolve) => {
            setTimeout(() => {
                this.login(user).subscribe(
                    (data) => {
                        this.setSessionToken(data).then(res => {
                            if (res) {
                                const orderExists = JSON.parse(sessionStorage.getItem('order'));
                                const orderID = sessionStorage.getItem('oderID');
                                const redirectUrl = sessionStorage.getItem('redirect_url');
                                this.global.notification.success('Login', 'Nome de usuário e senha corretos, redirecionando...');
                                if (orderExists && orderID) {
                                    this.router.navigate(['/market/product/order/' + orderID]);
                                } else if (redirectUrl) {
                                    this.router.navigate([redirectUrl]);
                                    sessionStorage.removeItem('redirect_url');
                                } else {
                                    this.router.navigate(['/portal']);
                                }
                            } else {
                                this.router.navigate(['/erros/403']);
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            if (resolve) { resolve(false); }
                        });
                    },
                    err => {
                        console.log(err);
                        if (resolve) { resolve(false); }
                        this.global.notification.error('Login', 'Nome de usuário ou senha incorretos.');
                    }
                );
            }, 100);
        });
    }

}
